import React from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// helpers
import { getEventDate } from "helpers/Functions.jsx"

// page styles
import countdownTimerStyle from "assets/jss/material-kit-pro-react/components/countdownTimerStyle.jsx"
const useStyles = makeStyles(countdownTimerStyle)

const chilimaniaDate = getEventDate("chilimania")

export default function CountdownTimer() {
  const classes = useStyles()
  const calculateTimeLeft = () => {
    const difference =
      +new Date(chilimaniaDate.replace(/-/g, "/")) - +new Date()
    let timeLeft = {}
    if (difference > 0) {
      timeLeft = {
        day: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hour: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minute: Math.floor((difference / 1000 / 60) % 60),
        second: Math.floor((difference / 1000) % 60),
      }
    }
    return timeLeft
  }

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft())

  React.useEffect(() => {
    let timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    return () => clearTimeout(timer)
  }, [timeLeft])

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    timerComponents.push(
      <div className={classes.timerWrapper} key={interval}>
        <span className={classes.time}>
          {timeLeft[interval] ? timeLeft[interval] : "0"}
        </span>
        <span className={classes.units}>
          {timeLeft[interval] > 1 || timeLeft[interval] === 0
            ? `${interval}s`
            : interval}{" "}
        </span>
      </div>
    )
  })

  return (
    <>
      {timerComponents.length ? (
        timerComponents
      ) : (
        <span className={classes.timeExpired}>Chilimania's Happening Now!</span>
      )}
    </>
  )
}
