// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page styles
import timerStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/timerStyle.jsx"
import CountdownTimer from "components/CountdownTimer/CountdownTimer.jsx"
// core components
// import CountdownTimer from "components/CountdownTimer/CountdownTimer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(timerStyle)

export default function SectionTimer() {
  const { chiliPepperBackground } = useStaticQuery(graphql`
    query getTimerImages {
      chiliPepperBackground: file(
        relativePath: { eq: "backgrounds/chili-peppers-timer.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={chiliPepperBackground.childImageSharp.gatsbyImageData}
        objectPosition={"top"}
        alt="Event Photo"
      />
      <div className={classes.backgroundImageText}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={10} md={8} className={classes.timerItem}>
              <h3 className={classes.title}>Countdown to Chilimania</h3>
              <div className={classes.timerReveal}>
                <CountdownTimer />
              </div>
              {/* <h5 className={classes.subtitle}>Cancelled</h5>
              <h5 className={classes.subtitle}>See You Next Year!</h5> */}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}
