import {
  backgroundImage,
  backgroundImageContainer,
  backgroundImageText,
  blackColor,
  card,
  container,
  hexToRgb,
  mlAuto,
  mrAuto,
  section,
  sectionDark,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const timerStyles = (theme) => ({
  backgroundImage: {
    ...backgroundImage,
    height: "300px",
  },
  backgroundImageText,
  card,
  cardBody: {
    padding: "15px",
    "& form": {
      marginBottom: "0",
    },
  },
  cardForm: {
    margin: "0 0 0 14px",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0px 30px 0px",
    },
  },
  container: {
    ...container,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "650px",
    },
  },
  mlAuto,
  mrAuto,
  subtitle: {
    fontSize: "1.25rem",
    fontWeight: "500",
    color: whiteColor,
    marginBottom: "30px",
  },
  section: {
    ...section,
    ...sectionDark,
    ...backgroundImageContainer,
    position: "relative",
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)",
    },
  },
  textCenter,
  timerItem: {
    ...mlAuto,
    ...mrAuto,
    ...textCenter,
  },
  timerReveal: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    ...title,
    color: whiteColor,
    marginTop: "0",
  },
})

export default timerStyles
