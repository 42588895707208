import { whiteColor } from "assets/jss/material-kit-pro-react.jsx"

const countdownTimerStyle = (theme) => ({
  time: {
    fontSize: "2.25rem",
  },
  timeExpired: {
    fontSize: "1.5rem",
    color: whiteColor,
    fontWeight: "700",
    margin: "20px 20px 30px 20px",
  },
  timerWrapper: {
    color: whiteColor,
    fontWeight: "700",
    display: "flex",
    flexDirection: "column",
    margin: "20px 20px 30px 20px",
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 10px 15px 10px",
    },
  },
  units: {
    textTransform: "uppercase",
    fontSize: "1rem",
  },
})

export default countdownTimerStyle
